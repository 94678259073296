import React from 'react';

function Footer() {
    return (
        <div>
            <footer className="sticky-footer bg-white">
                <div className="copyright text-center my-auto">
                    <span>Take Control 0.6.3.3</span>
                </div>
            </footer>
        </div>
    );
}

export default Footer;