import React from 'react';
import UpdatableCard from "./UpdatableCard";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import {InputGroup, Table} from "react-bootstrap";
import {btnoptions, ButtonList} from "../ui/ButtonList";
import {formatStats} from "../../helpers/helpers";

function RecipesCard({title, recipes, showModal, day}) {
    const [text, setText] = React.useState("");
    const [show_100g, setShow100g] = React.useState(false);

    if (!recipes.isSuccess) {
        return <div>Loading...</div>
    }

    let list = Object.values(recipes.data);

    list = list.filter((recipe) => recipe.name.toLowerCase().includes(text.toLowerCase()))
    if (list.length > 50) {
        list = list.slice(0, 50)
        title += " (showing first 50)"
    }

    let recipeshtml = list.map((value, index) => {
        const formattedStats = formatStats(value.stats)
        const suffix = show_100g ? "_100g": "_serving"
        console.log(formattedStats)
        console.log(value)
        return (
            <tr key={"recipe-" + index}>
                <td>{value["name"]}</td>
                <td>
                    {formattedStats["energy" + suffix]}
                </td>
                <td>
                    {formattedStats["carbohydrates" + suffix]}
                </td>
                <td>
                    {formattedStats["fat" + suffix]}
                </td>
                <td>
                    {formattedStats["proteins" + suffix]}
                </td>
                <td>
                    <ButtonList item={value}
                                options={[btnoptions.EDIT, btnoptions.DELETE]}
                                onEdit={() => showModal(value)}
                    />
                </td>
            </tr>
        )
    })

    return (<UpdatableCard title={title} empty={false}>
        <Form.Check
            type="switch"
            label="Show per 100g instead of serving"
            id="custom-switch"
            onChange={(e) => setShow100g(e.target.checked)}
            checked={show_100g}
        />
        <InputGroup style={{marginBottom: 5 + "px"}}>
            <Form.Control placeholder={"Search recipes..."}
                          onChange={(event) => setText(event.target.value)}/>
            <Button onClick={(e) => {
                e.preventDefault();
                showModal()
            }}>
                Add new recipe
            </Button>
        </InputGroup>
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Name</th>
                <th>Energy</th>
                <th>Carbs</th>
                <th>Fat</th>
                <th>Protein</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {recipeshtml}
            </tbody>
        </Table>
    </UpdatableCard>);
}

export default RecipesCard