import {BarcodeScanner} from 'react-barcode-scanner'
import "react-barcode-scanner/polyfill"
import React, {useEffect, useState} from "react";
import {fetchFooddbByBarcode} from "../../../api/foodApiHandler";
import {convertDBItem} from "../../../helpers/helpers";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";

export function Scanner(props) {
    const [searching, setSearching] = useState(false)
    const [barcode, setBarcode] = useState(null)
    const [lastError, setLastError] = useState(null)

    const handleScan = (data) => {
        setSearching(true)

        fetchFooddbByBarcode(data.rawValue).then((product) => {
            props.showIngredientModal()
            props.setIngredientEditData(convertDBItem(product["product"]))
            setSearching(false)
        }).catch((e) => {
            setLastError(e)
            setSearching(false)
        })
    }

    if (searching) {
        return <FontAwesomeIcon icon={solid("spinner")} spin/>
    }

    // check if mobile or not https
    let supported = window.innerWidth < 768 && window.location.protocol === "https:"
    if (!supported) {
        return <div className="d-sm-flex align-items-center justify-content-between ml-4">
            <Form.Group controlId="barcode">
                <p className={"text-muted"}>Barcode Scanning not supported. Either your screen is too wide (not mobile)
                    or you are not using https.</p>
                <Form.Label>Barcode</Form.Label>
                <Form.Control type="text" placeholder="Enter barcode" onChange={(e) => setBarcode(e.target.value)}/>
                <Button onClick={() => handleScan({rawValue: barcode})}>Search</Button>
            </Form.Group>
        </div>
    }
    return (
        <div>
            <p>
                {lastError}
            </p>
            <BarcodeScanner
                options={{formats: ['ean_13', 'ean_8']}}
                onCapture={handleScan}
            />
        </div>
    );
}
