import React, {forwardRef, useState} from 'react';
import Foodplan from "../../cards/food/Foodplan";
import FoodStatsCard from "../../cards/food/FoodStatsCard";
import IngredientsCard from "../../cards/IngredientsCard";
import RecipesCard from "../../cards/RecipesCard";
import DatePicker from "react-datepicker";
import {getCurrentTime, jsDateToMoment} from "../../../helpers/dateHelper";
import {authHeader} from "../../../api/authHeader";
import {handleResponse} from "../../../api/handleResponse";
import {fetchFoodplan, fetchFoodStats} from "../../../api/foodApiHandler";
import {addUpRecipeStats} from "../../modals/AddRecipeModal";
import {dictEmpty} from "../../../helpers/helpers";
import {useQuery} from "react-query";

const days = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
const CustomInput = forwardRef(({value, onClick}, ref) => (<button style={{
    cursor: 'pointer',
    padding: '5px 15px',
    border: '0',
    borderRadius: '4px',
    backgroundColor: '#216ba5',
    font: 'inherit',
    color: '#fff'
}} onClick={onClick} ref={ref}>
    {days[new Date(value).getDay()]}, {new Date(value).toLocaleDateString('de-DE')}
</button>));

function Food(props) {
    const state = {
        stats: {},
        currentVersion: 1,
        showRecipeModal: false
    }
    const [date, setDate] = useState(getCurrentTime())
    const fetchAllPlansQuery = useQuery({
        queryKey: ['food', 'plans', ['lisa', 'jakob', 'both'], date.unix()],
        queryFn: () => fetchFoodplan(["lisa", "jakob", "both"], date.unix()).then((values) => {
            const result = {}
            for (const value of values) {
                result[value.plan_for] = value.data
            }
            return result
        })
    })

    const recipesQuery = useQuery({
        queryKey: ['food', 'recipes'],
        queryFn: () => fetch(process.env.REACT_APP_API_URL + '/food/recipes', {
            method: 'GET', headers: authHeader({'Content-Type': 'application/json'}),
        })
            .then(handleResponse)
            .then(data => {
                let recipesDict = {};
                for (let recipe of data) {
                    recipesDict[recipe["name"]] = recipe;
                }
                return recipesDict;
            })
    })

    const ingredientsQuery = useQuery({
            queryKey: ['food', 'ingredients'],
            queryFn: () => fetch(process.env.REACT_APP_API_URL + '/food/ingredients', {
                method: 'GET', headers: authHeader({'Content-Type': 'application/json'}),
            })
                .then(handleResponse)
                .then(data => {
                    let ingredientDict = {};
                    for (let ingredient of data) {
                        ingredientDict[ingredient["name"]] = ingredient;
                    }
                    return ingredientDict;
                })
        }
    )

    const fetchStatsQuery = useQuery({
        queryKey: ['food', 'stats', ['lisa', 'jakob'], date.unix()],
        queryFn: () => fetchFoodStats(["lisa", "jakob"], date.unix()).then((values) => {
            const result = {}
            for (const value of values) {
                result[value.plan_for] = value.data
            }
            return result
        })
    })

    if (!fetchAllPlansQuery.isSuccess || !recipesQuery.isSuccess || !ingredientsQuery.isSuccess || !fetchStatsQuery.isSuccess) {
        return <div>Loading...</div>
    }

    if (!dictEmpty(ingredientsQuery.data) && !dictEmpty(recipesQuery.data)) {
        for (let recipe of Object.values(recipesQuery.data)) {
            if (!recipe["stats"]) {
                recipe["stats"] = addUpRecipeStats(1, recipe["ingredients"], ingredientsQuery.data)
            }
        }
    }

    return (<div className="container-fluid">
        <div className="mb-4">
            <h1 className="h3 mb-0 text-gray-800" style={{display: 'inline'}}>Food planning</h1>
            <button style={{marginLeft: ".5em", float: "right"}} className="btn btn-sm" onClick={() => {
                const newDate = date.clone()
                newDate.add(1, "days")
                setDate(newDate)
            }}>
                <i className="fas fa-caret-right"/>
            </button>
            <div style={{display: 'inline-block', float: "right"}}>
                <DatePicker selected={new Date(date.format('YYYY/MM/DD HH:mm:ss'))}
                            onChange={(date) => {
                                setDate(jsDateToMoment(date))
                            }} todayButton="Today" customInput={<CustomInput/>}/>
            </div>
            <button style={{marginLeft: ".5em", float: "right"}} className="btn btn-sm"
                    onClick={() => {
                        const newDate = date.subtract(1, "days")
                        setDate(newDate)
                    }}>
                <i className="fas fa-caret-left"/>
            </button>
        </div>

        <div className="row">
            <div className="col-lg-2">
                <FoodStatsCard for={"lisa"} stats={fetchStatsQuery.data["lisa"]} recipes={recipesQuery.data}
                               ingredients={ingredientsQuery.data}
                               plans={[fetchAllPlansQuery.data["lisa"], fetchAllPlansQuery.data["both"]]}/>
            </div>
            <div className="col-lg-4">
                <Foodplan for={"lisa"} date={date} plan={fetchAllPlansQuery.data["lisa"]}
                          recipes={recipesQuery.data} ingredients={ingredientsQuery.data}
                />
            </div>
            <div className="col-lg-4">
                <Foodplan for={"jakob"} date={date} plan={fetchAllPlansQuery.data["jakob"]}
                          recipes={recipesQuery.data} ingredients={ingredientsQuery.data}/>
            </div>
            <div className="col-lg-2">
                <FoodStatsCard for={"jakob"} stats={fetchStatsQuery.data["jakob"]} recipes={recipesQuery.data}
                               ingredients={ingredientsQuery.data}
                               plans={[fetchAllPlansQuery.data["jakob"], fetchAllPlansQuery.data["both"]]}/>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-4">
                <RecipesCard day={date} recipes={recipesQuery.data} title={"Recipes"} showModal={() => {
                    props.showRecipeModal()
                }}/>
            </div>
            <div className="col-lg-4">
                <Foodplan for={"both"} date={date} plan={fetchAllPlansQuery.data["both"]}
                          recipes={recipesQuery.data} ingredients={ingredientsQuery.data}/>
            </div>
            <div className="col-lg-4">
                <IngredientsCard ingredients={ingredientsQuery.data} title={"Ingredients"}/>
            </div>
        </div>

    </div>);
}

export default Food;