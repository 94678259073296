import React, {useState} from 'react';
import TodosCard from "../../cards/TodosCard";
import TaskListCard from "../../cards/TaskListCard";
import {fetchInbox, fetchRecommendations, fetchUpcoming} from "../../../api/todoApiHandler";
import {getCurrentTime} from "../../../helpers/dateHelper";
import {btnoptions} from "../../ui/ButtonList";
import {useQuery} from "react-query";

function Dashboard(props) {
    const [date, setDate] = useState(getCurrentTime())

    const inbox = useQuery({
        queryKey: ['todos', 'inbox'],
        queryFn: fetchInbox
    })

    const recommendations = useQuery({
        queryKey: ['todos', 'recommendations'],
        queryFn: fetchRecommendations
    })

    const upcoming = useQuery({
        queryKey: ['todos', 'upcoming'],
        queryFn: fetchUpcoming
    })

    const [show, setShow] = useState(false)

    if (!inbox.isSuccess || !recommendations.isSuccess || !upcoming.isSuccess) {
        return <div>Loading...</div>
    }

    return (
        <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
            </div>

            <div className="row">
                <div className="col-lg-6">
                    <TodosCard showTodoModal={props.showTodoModal} changeDate={(date) => {
                        setDate(date.clone());
                    }} currentDate={date}/>
                </div>
                <div className="col-lg-6">
                    <TaskListCard currentDate={date}
                                  tasks={inbox.data}
                                  title={"Inbox"}
                                  options={[btnoptions.PLAN_NOW, btnoptions.START_NOW, btnoptions.CHECK_OFF_TODO, btnoptions.DELETE, btnoptions.EDIT]}
                                  showTodoModal={props.showTodoModal}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 mb-4">
                    <TaskListCard currentDate={date}
                                  tasks={upcoming.data}
                                  title={"Tasks tomorrow"}
                                  options={[btnoptions.PLAN_NOW, btnoptions.START_NOW, btnoptions.CHECK_OFF_TODO, btnoptions.DELETE, btnoptions.EDIT]}
                                  showTodoModal={props.showTodoModal}/>
                </div>
                <div className="col-lg-6">
                    <TaskListCard currentDate={date}
                                  tasks={recommendations.data}
                                  title={"Recommendations"}
                                  options={[btnoptions.PLAN_NOW, btnoptions.START_NOW, btnoptions.CHECK_OFF_TODO, btnoptions.DELETE, btnoptions.EDIT]}
                                  showTodoModal={props.showTodoModal}/>
                </div>
            </div>
        </div>
    );

}

export default Dashboard;