import React, {useState} from 'react';
import {
    EVENING_TIME,
    getMorningTime,
    mongoToMoment,
    MORNING_TIME,
    NIGHT_TIME,
    NOON_TIME,
    SNACK_TIME
} from "../../../helpers/dateHelper";
import {DragDropContext} from "@hello-pangea/dnd";
import DatepickerCard from "../../cards/DatepickerCard";
import {updateItem} from "../../../api/entityApiHandler";
import {
    fetchIngredients,
    fetchPlannedFood,
    fetchStats
} from "../../../api/foodApiHandler";
import FoodPlannerCard from "../../cards/food/FoodPlannerCard";
import {authHeader} from "../../../api/authHeader";
import {handleResponse} from "../../../api/handleResponse";
import {addUpRecipeStats} from "../../modals/AddRecipeModal";
import {useMutation, useQuery, useQueryClient} from "react-query";

function FoodPlanner(props) {
    const queryClient = useQueryClient()

    const planned_food = useQuery({
        queryKey: ['food', 'planned_food'],
        queryFn: () => fetchPlannedFood(date).then((values) => {
            const result = []
            for (let i = 0; i < values.length; i++) {
                result[i] = {morning: [], snacks: [], noon: [], evening: []}
                for (const entry of values[i]) {
                    for (const food of entry["data"]) {
                        result[i][food["part_of_day"]].push(food)
                    }
                }
            }
            return result
        })
    })

    const stats = useQuery({
        queryKey: ['food', 'stats'],
        queryFn: () => fetchStats(date)
    })

    const fetchRecipes = () => {
        return fetch(process.env.REACT_APP_API_URL + '/food/recipes', {
            method: 'GET', headers: authHeader({'Content-Type': 'application/json'}),
        })
            .then(handleResponse)
            .then(data => {
                let recipesDict = {}
                for (let recipe of data) {
                    recipesDict[recipe["name"]] = recipe
                }
                return recipesDict;
            });
    }

    const [date, setDate] = useState(getMorningTime())
    const ingredients = useQuery({
        queryKey: ['food', 'ingredients'],
        queryFn: fetchIngredients
    })
    const recipes = useQuery({
        queryKey: ['food', 'recipes'],
        queryFn: fetchRecipes
    })

    const updateItemMutation = useMutation({
        mutationFn: (item) => {
            item["type"] = "fooditem"
            return updateItem(item);
        },
    })

    function handleDrag(source, destination) {
        // droppableId = e.g. "0-snacks" (means day 0, snacks) or "4-morning" (means day 4, morning)
        const sourceDay = source.droppableId.split("-")[0]
        const targetDay = destination.droppableId.split("-")[0]
        const targetPartOfDay = destination.droppableId.split("-")[1]
        const sourcePartOfDay = source.droppableId.split("-")[1]

        const sourceColumn = planned_food.data[sourceDay][sourcePartOfDay];
        const targetColumn = planned_food.data[targetDay][targetPartOfDay];
        const newSourceColumn = Array.from(sourceColumn)
        const newTargetColum = sourceColumn === targetColumn ? newSourceColumn : Array.from(targetColumn)
        const newPlannedFood = Array.from(planned_food.data)

        const day = date.clone().add(targetDay, "days")

        const sourceIndex = source.index
        const foodItem = sourceColumn[sourceIndex]
        const newFoodItem = {...foodItem, partOfDay: targetPartOfDay, day: day}

        updateItemMutation.mutate(newFoodItem)

        if (sourceColumn === targetColumn) {
            newSourceColumn.splice(sourceIndex, 1)
            newSourceColumn.splice(destination.index, 0, newFoodItem)
        } else {
            newSourceColumn.splice(sourceIndex, 1)
            newTargetColum.splice(destination.index, 0, newFoodItem)
        }

        newPlannedFood[sourceDay][sourcePartOfDay] = newSourceColumn
        newPlannedFood[targetDay][targetPartOfDay] = newTargetColum

        queryClient.setQueryData(['food', 'planned_food'], newPlannedFood)
    }

    const onDragEnd = result => {
        const {destination, source} = result;
        if (!destination) {
            return;
        }
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }
        handleDrag(source, destination);
    };

    if (!planned_food.isSuccess || !stats.isSuccess || !ingredients.isSuccess || !recipes.isSuccess) {
        return <div>Loading...</div>
    }

    for (let recipe of Object.values(recipes.data)) {
        if (!recipe["stats"]) {
            recipe["stats"] = addUpRecipeStats(1, recipe["ingredients"], ingredients.data)
        }
    }

    return (<div className="container-fluid">
            <DragDropContext onDragEnd={onDragEnd}>
                <div>
                    <div className="row">
                        <div className="col-lg-12">
                            <DatepickerCard title={"Your week"} empty={false} currentDate={date}
                                            daysPerClick={7}
                                            changeDate={(date) => {
                                                setDate(date)
                                            }}>
                                {<FoodPlannerCard plannedFood={planned_food.data} date={date}
                                                  ingredients={ingredients.data}
                                                  recipes={recipes.data} stats={stats.data}/>}
                            </DatepickerCard>
                        </div>
                    </div>
                </div>
            </DragDropContext>
        </div>

    );
}

export default FoodPlanner;
