import React, {useRef, useState} from 'react';
import styled from "styled-components";
import {Draggable} from "@hello-pangea/dnd";
import ReactTooltip from "react-tooltip";
import {deleteEntity, updateItem} from "../../../api/entityApiHandler";
import {unparseFoodPlanItem} from "../../../parser/FoodplanItemParser";
import {parseAmount} from "../../../parser/RecipeParser";

const FoodItem = styled.div`
    border: 1px solid lightgray;
    border-radius: 3px;
    padding: 8px;
    margin-bottom: 4px;
    background: white;
    color: black;
    max-width: 220px;
    position: relative;
    display: flex;
    ${({person}) => {
        if (!person) {
            return "";
        }
        let text = "";
        if (person === "lisa" || person === "both") {
            text += "border-right-width: 0.25rem; border-right-style: solid; border-right-color: pink;"
        }
        if (person === "jakob" || person === "both") {
            text += "border-left-width: 0.25rem; border-left-style: solid; border-left-color: lightblue;"
        }
        return text
    }};
`

const Text = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 4px;
`

const AmountTag = styled.small`
    color: #6B778C;
    text-align: right;
    flex-grow: 1;
    flex-shrink: 1;
`

function DraggableFoodItem(props) {

    const [editMode, setEditMode] = useState(false);
    const [text, setText] = useState("");
    const tooltip = useRef(null)

    const handleClick = (e) => {
        if (e.detail === 2) {
            setEditMode(true)
            setText(unparseFoodPlanItem(props.food))
        }
    };

    const handleKey = event => {
        if (event.keyCode === 27) {
            event.preventDefault();
            setEditMode(false)
            setText("")
        }
    }

    const updateText = (e) => {
        setText(e.target.value)
    }

    let inner
    if (editMode) {
        inner = provided => (<FoodItem
            ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <form
                onSubmit={(e) => {
                    try {
                        e.preventDefault()
                        if (text !== "") {
                            // Basically only the amount can be updated
                            const words = text.split(' ')
                            let amount = parseAmount(words[words.length - 1])

                            let modifiedFoodItem = {
                                _id: props.food["_id"],
                                name: props.food["name"],
                                food_type: props.food["food_type"],
                                for_user: props.food["for_user"],
                                day: props.food["day"],
                            };
                            if (amount) {
                                modifiedFoodItem["amount"] = amount["amount"]
                                modifiedFoodItem["unit"] = amount["unit"]
                            }

                            updateItem(modifiedFoodItem)
                            setText("")
                            setEditMode(false)
                        } else {
                            deleteEntity(props.food)
                            tooltip.current.setAttribute("data-tip", "Food item deleted")
                            tooltip.current.setAttribute("data-type", "info")
                            ReactTooltip.show(tooltip.current)
                            setTimeout(() => {
                                setText("")
                                setEditMode(false)
                                ReactTooltip.hide(tooltip.current)
                            }, 300)
                        }
                    } catch (e) {
                        tooltip.current.setAttribute("data-tip", e.toString())
                        tooltip.current.setAttribute("data-type", "error")
                        ReactTooltip.show(tooltip.current)
                    }
                }}
                className={"mr-auto w-100 navbar-search"}
            >
                <ReactTooltip type={"error"}/>
                <p ref={ref => tooltip.current = ref} data-tip={"Unknown Error"}></p>
                <div className="input-group">
                    <input type="text" className="form-control bg-light border-0 small"
                           placeholder={props.placeholder}
                           onChange={updateText}
                           value={text}
                           onKeyDown={handleKey}
                           autoFocus
                    />
                </div>
            </form>
        </FoodItem>)
    } else {
        const item = props.food
        inner = provided => (<FoodItem person={item["for_user"]}
                                       onClick={handleClick}
                                       ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <Text>{item.name}</Text>
            <AmountTag>{item["amount"] + item["unit"]}</AmountTag>
        </FoodItem>)
    }
    return <Draggable draggableId={props.uniquename} index={props.index}>
        {inner}
    </Draggable>
}

export default DraggableFoodItem;