import {authHeader} from "./authHeader";
import {handleResponse} from "./handleResponse";

export function fetchFoodplan(plans, timestamp) {
    const requestOptions = {
        method: 'GET', headers: authHeader({'Content-Type': 'application/json'}),
    };
    const promises = []
    for (const plan_for of plans) {
        promises.push(fetch(process.env.REACT_APP_API_URL + '/food/plan/' + plan_for + "?day=" + timestamp, requestOptions)
            .then(handleResponse)
            .then(data => {
                data.sort(function () {
                    return 0 // TODO
                })

                return {data: data, plan_for: plan_for}
            }));
    }
    return Promise.all(promises)
}

export function fetchFoodStats(plans, timestamp) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader({'Content-Type': 'application/json'}),
    };

    const promises = []
    for (const plan_for of plans) {
        promises.push(fetch(process.env.REACT_APP_API_URL + '/food/stats/' + plan_for + "?day=" + timestamp, requestOptions)
            .then(handleResponse).then(data => {
                return {data: data, plan_for: plan_for}
            }));
    }
    return Promise.all(promises)
}

export function submitIngredient(dict) {
    const requestOptions = {
        method: 'POST', headers: authHeader({'Content-Type': 'application/json'}), body: JSON.stringify(dict),
    };

    return fetch(process.env.REACT_APP_API_URL + '/food/ingredients', requestOptions)
        .then(handleResponse)
}

export function submitRecipe(dict) {
    const requestOptions = {
        method: 'POST', headers: authHeader({'Content-Type': 'application/json'}), body: JSON.stringify(dict),
    };
    return fetch(process.env.REACT_APP_API_URL + '/food/recipes', requestOptions)
        .then(handleResponse)
}

export const fetchPlannedFood = (date) => {
    const promises = []
    for (let i = 0; i < 7; i++) {
        let day = date.unix() + i * 60 * 60 * 24;
        promises.push(fetchFoodplan(["lisa", "jakob", "both"], day))
    }
    return Promise.all(promises)
}

export const fetchStats = (date) => {
    const promises = []
    for (let i = 0; i < 7; i++) {
        let day = date.unix() + i * 60 * 60 * 24;
        promises.push(fetchFoodStats(["lisa", "jakob"], day))
    }
    return Promise.all(promises)

}

export function submitFoodPlanItem(forid, entry, day, partOfDay) {
    const requestOptions = {
        method: 'POST', headers: authHeader({'Content-Type': 'application/json'}), body: JSON.stringify({
            name: entry["name"],
            amount: entry["amount"],
            day: day.toDate(),
            partOfDay: partOfDay,
            food_type: entry["food_type"],
            unit: entry["unit"]
        }),
    };

    return fetch(process.env.REACT_APP_API_URL + '/food/plan/' + forid, requestOptions)
        .then(handleResponse)
}

export function fetchFooddb(text) {
    const requestOptions = {
        method: 'GET'
    };

    return fetch("https://de.openfoodfacts.org/cgi/search.pl?search_terms=" + text + "&search_simple=1&action=process&json=1", requestOptions)
        .then(handleResponse)
}

export function fetchFooddbByBarcode(barcode) {
    const requestOptions = {
        method: 'GET'
    };

    return fetch("https://de.openfoodfacts.org/api/v2/product/" + barcode + ".json", requestOptions)
        .then(handleResponse)
}

export const fetchIngredients = () => {
    return fetch(process.env.REACT_APP_API_URL + '/food/ingredients', {
        method: 'GET', headers: authHeader({'Content-Type': 'application/json'}),
    })
        .then(handleResponse)
        .then(data => {
            let ingredientDict = {};
            for (let ingredient of data) {
                ingredientDict[ingredient["name"]] = ingredient;
            }
            return ingredientDict;
        });
}